@import './_breakpoints.scss';

@mixin gridTwo($gap_y: 0, $gap_x: 0) {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: $md) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridThree($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridFour($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: 1700px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridFive($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: 1700px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: $lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridSix($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: 1700px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin font40 {
  font-weight: 700;
  font-size: calc(14px + (37 - 14) * (100vw - 280px) / (2560 - 280));
}

@mixin font36 {
  font-weight: 600;
  font-size: calc(12px + (32 - 12) * (100vw - 280px) / (2560 - 280));
}

@mixin font32 {
  font-weight: 500;
  font-size: calc(10px + (29 - 10) * (100vw - 280px) / (2560 - 280));
}

@mixin font29 {
  font-weight: 400;
  font-size: calc(10px + (25 - 10) * (100vw - 280px) / (2560 - 280));
}

@mixin font24 {
  font-weight: 500;
  font-size: calc(12px + (21 - 12) * (100vw - 280px) / (2560 - 280));
}

@mixin font20 {
  font-weight: 500;
  font-size: calc(12px + (18 - 12) * (100vw - 280px) / (2560 - 280));
}

@mixin font18 {
  font-weight: 500;
  font-size: calc(10px + (16 - 10) * (100vw - 280px) / (2560 - 280));
}

@mixin font16 {
  font-weight: 500;
  font-size: calc(10px + (14 - 10) * (100vw - 280px) / (2560 - 280));
}

@mixin font14 {
  font-weight: 500;
  font-size: calc(10px + (13 - 10) * (100vw - 280px) / (2560 - 280));
}
