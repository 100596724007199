@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.modalWrapper {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  z-index: 250000;
  padding: 20px 20px;

  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

  .modalContentWrapper {
    position: relative;
    overflow-y: auto;
    max-width: 1000px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 12.17px;
    &::-webkit-scrollbar:vertical {
      display: none;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1.2px solid #e2e2ea;
      padding-top: calc(12px + (30 - 12) * (100vw - 280px) / (2560 - 280));
      padding-bottom: calc(12px + (30 - 12) * (100vw - 280px) / (2560 - 280));
      padding-left: calc(18px + (80 - 18) * (100vw - 280px) / (2560 - 280));
      padding-right: calc(18px + (80 - 18) * (100vw - 280px) / (2560 - 280));
      box-sizing: border-box;
      p {
        font-style: normal;
        font-weight: 700;
        color: $black;
        @include font40;
      }

      img {
        cursor: pointer;
        height: calc(10px + (29 - 10) * (100vw - 280px) / (2560 - 280));
        width: calc(10px + (29 - 10) * (100vw - 280px) / (2560 - 280));
      }
    }

    .body {
      padding-top: calc(12px + (89 - 12) * (100vw - 280px) / (2560 - 280));
      padding-bottom: calc(12px + (67 - 12) * (100vw - 280px) / (2560 - 280));
      padding-right: calc(18px + (65 - 18) * (100vw - 280px) / (2560 - 280));
      padding-left: calc(18px + (61 - 18) * (100vw - 280px) / (2560 - 280));

      .btnClass {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.fixedClass {
  top: 0;
  position: sticky;
  background-color: #ffffff;
  z-index: 10;
}
